<template>
  <div class="mx-3 sm:mx-3 md:w-8 md:mx-auto md:mt-4 lg:w-8 lg:mx-auto lg:mt-4">
  <Toast />
  <Card>
    <template #title>
      <text style="font-size: 28px">Questionário</text>
    </template>
    <template #subtitle>
      Preencha o questionário abaixo para ir para a teleconsulta
    </template>
    <template #content>
      <div v-for="(item, index) in questionarios" :key="index">
        <text style="font-size: 18px">{{ item.ordem }}- {{ item.pergunta }}</text>
        <br />
        <InputText v-if="item.tipo === 'R'" id="" v-model="item.resposta" style="width: 100%; margin-top: 4px; margin-bottom: 12px;"/>
        <div class="field-radiobutton" v-if="item.tipo === 'ME'" style="width: 100%; margin-top: 4px; margin-bottom: 12px;">
          <div v-for="(opcoes, index) in item.opcoes" :key="index" class="mr-3">
            <RadioButton :value="opcoes" v-model="item.resposta" />
            <label>{{opcoes}}</label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
        <Button icon="pi pi-check" label="Salvar" @click="save" />
    </template>
  </Card>
  </div>
</template>

<script>
import { getClientBase } from '@/services/http'

export default {
  data() {
    return {
      questionarios: [],
      telemedicina: null,
    }
  },
  async mounted () {
    const responseQuestionario = await getClientBase().get('/paciente-resposta-questionario/atendimento/1');
    if (responseQuestionario.data.length > 0) {
      this.$router.push(`/telemedicina-paciente/${this.$route.params.sala}`);
      return;
    }
    const responseQuestionarios = await getClientBase().get(`/questionarios`)
    this.questionarios = responseQuestionarios.data

    const responseTelemedicina = await getClientBase().get(`/telemedicinas/by-nome-sala/${this.$route.params.sala}`)
    this.telemedicina = {...responseTelemedicina.data}
  },
  methods: {
    async save() {
      let respostas = [];
      for(const questionario of this.questionarios) {
        if (questionario?.resposta) {
          respostas.push({
            nomePaciente: this.telemedicina.nomePaciente,
            resposta: questionario.resposta,
            atendimentoId: 1,
            questionarioId: questionario.id
          });
        } else {
          this.$toast.add({ severity: 'warn', summary: 'Responda todas as perguntas!', life: 3000 });
          return;
        }
      }

      await getClientBase().post('/paciente-resposta-questionario', respostas);
      this.$toast.add({ severity: 'success', summary: 'Questionário salvo!', life: 3000 });
      this.$router.push(`/telemedicina-paciente/${this.$route.params.sala}`)
    }
  }
};
</script>
